import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Failure.css'; // Make sure to create and style this CSS file
import { useDispatch } from 'react-redux';
import { setActiveButton, setShowSupportModal } from '../../redux/features/footerMenuSlice';

const Failure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRetryPayment = () => {
    navigate('/'); // Redirect to payment page or wherever you'd like
  };

  const handleContactSupport = () => {
    navigate('/'); // Redirect to contact support page or wherever you'd like
    dispatch(setShowSupportModal(true));
    dispatch(setActiveButton('support'));
  };

  return (
    <div className="failure-container">
      <CloseCircleOutlined className="failure-icon failure-animated" />
      <h1 className="failure-message">Payment Failed</h1>
      <p className="failure-description">
        We encountered an issue while processing your payment. Please try again.
      </p>
      <div className="failure-buttons">
        <Button type="primary" onClick={handleRetryPayment} className="failure-order-button center">
          Back to Order
        </Button>
        <Button type="default" onClick={handleContactSupport} className="failure-support-button center">
          Contact Support
        </Button>
      </div>
    </div>
  );
};

export default Failure;
