import React, { useEffect, useRef, useState } from 'react';
import './AllQueries.css';
import { message } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveButton } from '../redux/features/footerMenuSlice';
import { useNavigate } from 'react-router-dom';
import HeroSvg from '../components/Layout/HeroSvg';
import SideMenu from '../components/Header/SideMenu';
import Layout from '../components/Layout/Layout';
import axios from 'axios';

const AllQueries = ({ show }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitButtonRef = useRef(null);
  const [sideMenu, setSideMenu] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState('');
  const [allQueries, setAllQueries] = useState(null);
  const [singleQuery, setSingleQuery] = useState(null);
  const [tab, setTab] = useState(0);
 
  const onClose = () => {
    if(tab === 0){
      dispatch(setActiveButton('home'));
      return navigate('/') 
    } else {
      setTab(0)
    }
    
  }

  async function handleSubtmit(id) {
    if(!query){
      return message.error('please add some query')
    }
    try {
      const res = await axios.post("/api/contact/update-query", {
        id: id,
        msg: query,
        person: "user",
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        message.success(res.data.message);
        const updatedQuery = { ...singleQuery, msg: [...singleQuery.msg, { msg: query, person: "user" }] };
        setSingleQuery(updatedQuery);
        setQuery("");
        getUserQuery();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getUserQuery() {
    try {
      const res = await axios.post("/api/contact/get-user-query", {
        email: user?.email,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setAllQueries(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserQuery();
    }
    // eslint-disable-next-line
  }, [user]);

  const onDeleteQuery = (data) => {
    // Add delete functionality here
  }

  useEffect(() => {
    // Focus the button when the component mounts
    if (submitButtonRef.current) {
      submitButtonRef.current.focus();
      submitButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [tab]);

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
          <HeroSvg />
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center" onClick={onClose}>
                <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <h2 className="mb-0">All Queries</h2>
            </div>
            <div className="top-right-content box-2 center" onClick={() => setSideMenu(!sideMenu)}>
              <svg width="18" height="18" fill="currentColor" className="bi bi-list text-white" viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </div>
          </div>

          <div className="all-queries-container px-3">
            {tab === 0 && <div className="all-queries-content">
                      <p className='mb-2 border bg-light all-queries-content-heading d-flex justify-content-start align-items-center'>
                        <span className='me-1'>Total Queries: </span>
                        <span>{allQueries?.length}</span>
                      </p>
                  <div className='p-2 px-3'>
                    {allQueries? allQueries?.map((item, index) => {
                      return (
                        <div key={item?._id} className='all-queries-content-item border-bottom py-2 px-2 rounded-3 bg-white mb-2'>
                          <div className='center gap-2'>
                            <div className='index-number border bg-light rounded-pill text-dark center'>{index + 1}</div>
                            <div className='fw-bold all-queries-content-item-type'>{item?.type}</div>
                          </div>
                          <div>
                            <div
                              className='center'
                              onClick={() => {
                                setTab(1);
                                setSingleQuery(item);
                              }}
                            >
                              {item?.status === "seen" ?
                              <svg width="25" height="25" fill="currentColor" className="text-danger" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                              </svg>
                              :
                              <svg width="25" height="25" fill="currentColor" className="text-success" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                              </svg>
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : 
                    <div className='text-center'>
                        <div class="spinner-border text-secondary" role="status">
                          <span class="visually-hidden"></span>
                        </div>
                    </div>
                    }
                  </div>
            </div>}

            {tab === 1 && <div className="all-queries-content border p-3">
              <div className="query-reply-container">
                {singleQuery?.msg?.map((item, index) => {
                  return (
                    <div key={index}
                      className={`query-msg ${
                        item?.person === "user" && "active"
                      }`}
                    >
                      <span>{item?.msg}</span>
                    </div>
                  );
                })}
                {singleQuery.status === "pending" && (
                  <textarea
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="my-3 form-control shadow-sm border px-3 py-2"
                    rows="4"
                  >
                  </textarea>
                )}

                {singleQuery.status === "seen" ? (
                  <>
                    <button
                      onClick={() => handleSubtmit(singleQuery?._id)}
                      className="closed-button mt-2"
                    >
                      Admin Has Closed this ticket
                    </button>
                  </>
                ) : (
                  <button
                    ref={submitButtonRef}
                    onClick={() => handleSubtmit(singleQuery?._id)}
                    className="update-button mt-3"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>}
          </div>
        </div>

        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </Layout>
    </>
  );
};

export default AllQueries;
