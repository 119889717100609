import axios from "axios";

export const getPayUHash = async (paymentObject) => {
  try {
    const response = await axios.post(
      "/api/payu/get-hash",
      {
        name: paymentObject.customer_name,
        email: paymentObject.customer_email,
        amount: paymentObject.txn_amount,
        transactionId: paymentObject.order_id,
        productName: paymentObject.product_name,
        udf1: paymentObject.udf1,
        udf2: paymentObject.udf2,
        udf3: paymentObject.udf3,
        udf4: paymentObject.udf4,
        udf5: paymentObject.udf5,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (response.data.success) {
      return response.data.hash;
    } else {
      throw new Error("Failed to get hash");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const submitPaymentForm = async (paymentObject, orderId, setLoader) => {
  try {
    const hash = await getPayUHash(paymentObject); // Await the hash retrieval
    if (!hash) {
      console.error("Hash not retrieved, form submission aborted");
      return; // Abort form submission if hash is not retrieved
    } else {
      setLoader(false);
    }
    const form = document.createElement("form");
    form.action = "https://secure.payu.in/_payment";
    // form.action = 'https://test.payu.in/_payment';
    form.method = "post";
    form.className = "w-100";

    const formFields = [
      // { name: 'key', value: 'PmqiX5' },
      { name: "key", value: "Rlram3" },
      { name: "txnid", value: orderId },
      { name: "productinfo", value: paymentObject.product_name },
      { name: "amount", value: paymentObject.txn_amount },
      { name: "email", value: paymentObject.customer_email },
      { name: "firstname", value: paymentObject.customer_name },
      { name: "surl", value: paymentObject.success_URL },
      { name: "furl", value: paymentObject.failure_URL },
      { name: "phone", value: paymentObject.customer_mobile },
      { name: "udf1", value: paymentObject.udf1 },
      { name: "udf2", value: paymentObject.udf2 },
      { name: "udf3", value: paymentObject.udf3 },
      { name: "udf4", value: paymentObject.udf4 },
      { name: "udf5", value: paymentObject.udf5 },
      { name: "hash", value: hash },
    ];

    formFields.forEach((field) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = field.name;
      input.value = field.value;
      form.appendChild(input);
    });

    const submitButton = document.createElement("input");
    submitButton.type = "submit";
    submitButton.value = "submit";
    form.appendChild(submitButton);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  } catch (error) {
    console.error("Error submitting payment form:", error);
  }
};
