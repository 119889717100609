import React, { useState } from 'react';
import SinglePurchaseModalReport from './SinglePurchaseModalReport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { message } from 'antd';

const PurchaseReports = ({ item, updatePurchaseOrder }) => {
  const [showModal, setShowModal] = useState(false);

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "processing":
        return "warning";
      case "success":
        return "success";
      case "Success":
        return "success";
      case "completed":
        return "success";
      case "rejected":
        return "danger";
      case "failure":
        return "danger";
      case "failed":
        return "danger";
      case "refunded":
        return "primary";
      case "inprogress":
        return "warning";
      case "refilling":
        return "secondary";
      case "cancelled":
        return "dark";
      default:
        return "";
    }
  };

  const handleItemClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    updatePurchaseOrder();
  };

  const copyToClipboard = (event, text) => {
    event.stopPropagation(); 
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Order ID copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy!');
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
      <div
        key={item?.orderId}
        className="list w-100 d-flex justify-content-between align-items-center mt-2 p-2 border border-bottom rounded-4 bg-light"
        onClick={handleItemClick}
      >
        <div className="center gap-2">
          <div className="add-icon-box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-bag"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
            </svg>
          </div>
          <div className="d-flex justify-content-center align-items-start flex-column">
            <span className="add-money">
              {item.productinfo?.toUpperCase()}
            </span>
            <span className="payment-order-id center gap-1" onClick={(event)=>{copyToClipboard(event, `Purchase Id: ${item?.orderId}`)}}>
              <ContentCopyIcon className='fs-6'/><span>{item?.orderId}</span>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-end flex-column">
          <div className='center gap-2'>
            <h4 className="mb-0 payment-amount">
              ₹{parseFloat(item?.amount).toFixed(2)}
            </h4>
            <svg width="16" height="16" fill="currentColor" className={`text-${getStatusClass(item?.status?.toLowerCase())}`}  viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
            </svg>
          </div>
          <div className="center gap-2">
            <span className="payment-date no-wrap">
              {new Date(item?.createdAt).toLocaleString("default", {
                day: "2-digit",
                month: "short",
              })}
            </span>
            <span className={`text-${getStatusClass(item?.status?.toLowerCase())} payment-status`}>
              {item?.status?.toLowerCase()}
            </span>
          </div>
        </div>
      </div>

      {showModal && <SinglePurchaseModalReport onClose={handleCloseModal} item={item} />}
    </>
  );
};

export default PurchaseReports;
