import React, { useState } from "react";
import "./PrivacyPolicyMobile.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../components/Header/SideMenu";
import HeroSvg from "../../components/Layout/HeroSvg";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import { ChevronLeftSvg, ListSvg } from "../../components/AllSvgModulesExport/LoginSvg";
import Layout from "../../components/Layout/Layout";

const RefundPolicyMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);

  const onClose = () => {
    dispatch(setActiveButton("home"));
    navigate("/");
  };

  return (
    <>
    <Layout>
      <div className="account-modal-container privacy-policy d-lg-none d-md-none">
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <ChevronLeftSvg />
            </div>
            <h2 className="mb-0">Refund Policy</h2>
          </div>
          <div className="top-right-content box-2 center" onClick={() => setSideMenu(!sideMenu)}>
            <ListSvg />
          </div>
        </div>

        <div className="privacy-policy-container px-3">
          <div className="privacy-policy-content px-3 mb-5">
          <h5 className="mt-4">Refund Eligibility</h5>
          <p className="mb-0">
            - Refunds are only applicable for in-game purchases made on our
            website.
          </p>
          <p className="mb-0">
            - To be eligible for a refund, users must submit a refund request
            within [24] hours of the original purchase.
          </p>
          <p>- Refund will not be processed if you put wrong details.</p>

          <h5 className="mt-4">Valid Reasons for Refunds</h5>
          <p>
            - Refunds will be considered for unauthorized transactions, technical
            issues causing non-delivery of in-game items, or other verifiable
            errors.
          </p>

          <h5 className="mt-4">Refund Process</h5>
          <p>
            - Users should submit refund requests via our customer support email
            topupplaygroundofficial@gmail.com
          </p>
          <p>
            - Provide the transaction details, including the order number, date of
            purchase, and a detailed explanation of the issue.
          </p>

          <h5 className="mt-4">Refund Approval</h5>
          <p> - Refunds are subject to approval and may require investigation.</p>
          <p>
            - We reserve the right to refuse a refund if the request is beyond the
            specified refund period or if we determine the purchase was not made
            in good faith.
          </p>
          <h5 className="mt-4">Refund Methods</h5>
          <p>
            - Approved refunds will be processed using the original payment
            method.
          </p>
          <p>- In certain cases, alternative refund methods may be considered.</p>
          <h5 className="mt-4">Non-Refundable Items</h5>
          <p>
            - Certain in-game items or services may be non-refundable. Users are
            advised to check product descriptions before making a purchase.
          </p>
          <h5 className="mt-4">Chargebacks</h5>
          <p>
            - Users are encouraged to contact us for resolution before initiating
            any chargebacks. Chargebacks may result in the suspension of accounts.
          </p>
          <h5 className="mt-4">Policy Changes</h5>
          <p>
            - We reserve the right to update or modify the refund policy at any
            time. Users will be notified of any changes.
          </p>
          <p>
            <i>
              By using our website and making in-game purchases, users acknowledge
              and agree to abide by this refund policy.
            </i>
          </p>
          </div>
        </div>
      </div>
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </Layout>
    </>
  );
};

export default RefundPolicyMobile;
