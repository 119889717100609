import React, { useEffect, useRef, useState } from 'react';
import './UserWallet.css';
import { message } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setActiveButton } from '../../redux/features/footerMenuSlice';
import Layout from '../../components/Layout/Layout';
import SideMenu from '../../components/Header/SideMenu';
import HeroSvg from '../../components/Layout/HeroSvg';
import { MdWallet } from "react-icons/md";
import getUserData from '../../utils/userDataService';

const UserWallet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitButtonRef = useRef(null);
    const [sideMenu, setSideMenu] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { balance } = useSelector((state) => state.data);
    const [walletHistory, setWalletHistory] = useState(null);

    async function getWalletHistory() {
        try {
        const res = await axios.post("/api/wallet/get-wallet-history",{
            email: user?.email
        },{
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        if (res.data.success) {
            setWalletHistory(res.data.data.reverse());
        } else {
            message.error(res.data.message);
        }
        } catch (error) {
        console.log(error);
        }
    }

    useEffect(() => {
        if (user !== null) {
            getWalletHistory();
            getUserData(dispatch);
        }
        // eslint-disable-next-line
    }, []);

    const onClose = () => {
        dispatch(setActiveButton('home'));
        return navigate('/') 
    }

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
            <HeroSvg />
            <div className="account-modal-container-content">
                <div className="top-left-content">
                <div className="box-1 center" onClick={onClose}>
                    <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path
                        fillRule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                    />
                    </svg>
                </div>
                <h2 className="mb-0">My Wallet</h2>
                </div>
                <div className="top-right-content box-2 center" onClick={() => setSideMenu(!sideMenu)}>
                <svg width="18" height="18" fill="currentColor" className="bi bi-list text-white" viewBox="0 0 16 16">
                    <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                    />
                </svg>
                </div>
            </div>

            <div className="user-wallet-container px-3 w-100">
                <div className="user-wallet-content px-3 py-3 mb-3">
                    <div className='d-flex justify-content-start align-items-start gap-3'>
                        <div className='user-wallet-icon p-3 rounded-pill'>
                            <MdWallet className='user-wallet-icon-img'/>
                        </div>
                        <div className='user-wallet-balance-content mt-1'>
                            <p className='mb-0'>Balance</p>
                            <span className='user-wallet-balance'>₹{(balance && user)? parseFloat(balance).toFixed(2) : parseFloat('0').toFixed(2)}</span>
                        </div>
                    </div>
                </div>

                <div className="user-wallet-content w-100">
                    <p className='mb-2 border bg-light user-wallet-content-heading'>Transactions history</p>
                    <div className='d-flex justify-content-start align-items-start gap-3 w-100 p-2'>
                        <div className='user-wallet-transaction-content mt-1 w-100'>
                            <div className='user-wallet-transaction-history-data'>
                                {walletHistory? walletHistory?.map((data)=>{
                                    return (
                                        <div key={data?._id} className='d-flex justify-content-between align-items-start border-bottom p-2 px-3 shadow-sm w-100 mb-2 rounded-3 bg-light'>
                                            <div className='user-wallet-transaction-history-heading'>
                                                {!data?.admin && <h3 className='mb-1'>{data?.p_info === "noSponsor"? "Add Money" : data?.p_info}</h3>}
                                                {data?.admin && <h3 className='mb-1'>{data?.p_info} <small>(by admin)</small></h3>}
                                                <span className='rounded py-1'>{data?.orderId}</span>
                                            </div>
                                            <div className='user-wallet-transaction-history-data-balance'>
                                                {(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund") ? 
                                                
                                                    <span className='text-success'>+ ₹{data?.price}</span>
                                                    :
                                                    <span className='text-danger'>- ₹{data?.price}</span>
                                                }
                                                <div>Avl Bal: {data?.balanceAfter}</div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className='text-center py-5'>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
      </Layout>
    </>
  );
};

export default UserWallet;
