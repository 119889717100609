import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import "./AdminNotification.css";

const AdminReferAndEarn = () => {
  const [amount, setAmount] = useState("");
  const [rechargeAmount, setRechargeAmount] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rechargeAmount === "" || isNaN(rechargeAmount)) {
      return message.error("Please enter recharge amount");
    }
    if (amount === "" || isNaN(amount)) {
      return message.error("Please enter amount");
    }
    try {
      const res = await axios.post(
        "/api/refer/set-amount",
        { amount: amount, rechargeAmount: rechargeAmount },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to Update");
    }
  };

  async function getAmount() {
    try {
      const res = await axios.get("/api/refer/get-amount", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAmount(res.data.data.amount);
        setRechargeAmount(res.data.data.rechargeAmount);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAmount();
    // eslint-disable-next-line
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users">
        <div className="page-title">
          <h3 className="m-0">Refer & Earn</h3>
        </div>
        <hr />
        <div className="noti-container">
          <h5 className="text-dark mb-4">Refer Amount</h5>
          <div className="form-fields">
            <input
              type="text"
              name="rechargeAmount"
              className="form-control mb-3"
              placeholder="Enter Recharge Amount"
              onChange={(e) => setRechargeAmount(e.target.value)}
              value={rechargeAmount}
            />
            <input
              type="text"
              name="sliderText"
              className="form-control mb-3"
              placeholder="Enter Refer Amount"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
            <button className="mb-5" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminReferAndEarn;
