import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import "./Account.css";
import getUserData from "../utils/userDataService";

const Account = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [form, setForm] = useState(null);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/user-profile-update", form,  {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setForm({ ...form, password: "" });
        getUserData(dispatch);
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setForm(user);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-accout-details" style={{ minHeight: "300px" }}>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
              <div className="col-12">
                <div className="account-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Name
                  </label>
                  <input
                    value={form?.fname}
                    onChange={(e) =>
                      setForm({ ...form, fname: e.target.value })
                    }
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="account-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Email
                  </label>
                  <input
                    value={form?.email}
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="account-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Mobile
                  </label>
                  <input
                    value={form?.mobile}
                    onChange={(e) =>
                      setForm({ ...form, mobile: e.target.value })
                    }
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="account-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Gender
                  </label>
                  <select
                    value={form?.gender}
                    onChange={(e) =>
                      setForm({ ...form, gender: e.target.value })
                    }
                    name="gender"
                    className="form-select"
                  >
                    <option value="" disabled selected>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="account-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    State
                  </label>
                  <select
                    value={form?.state}
                    onChange={(e) =>
                      setForm({ ...form, state: e.target.value })
                    }
                    className="form-select"
                    name="state"
                    required
                  >
                    <option value="" disabled selected>
                      Select State
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="account-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Password
                  </label>
                  <input
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                    type="text"
                    placeholder="New password"
                    className="form-control"
                  />
                </div>
              </div>
              <button onClick={handleUpdate} className="account-btn w-100">
                Update
              </button>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Account;
