import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios, { all } from "axios";
import { useSelector } from "react-redux";
import { message } from "antd";
import "./Orders.css";

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderLoader, setOrderLoader] = useState(false);

  const getAllUserOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllOrders(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getAllUserOrders();
    }
    // eslint-disable-next-line
  }, [user]);

  async function getOrderStatus(orderId, iid, pid) {
    try {
      setOrderLoader(true);
      let response;
      if (iid) {
        response = await axios.post(
          "/api/indian/updateStatus",
          { iid: iid, orderId: orderId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      } else if (pid) {
        response = await axios.post(
          "/api/prime/updateStatus",
          { pid: pid, orderId: orderId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      }

      if (response.data.success) {
        getAllUserOrders();
        message.success(response.data.message);
        setOrderLoader(false);
      } else {
        message.error(response.data.message);
        setOrderLoader(false);
      }
    } catch (error) {
      console.log(error);
      setOrderLoader(false);
    }
  }

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-order-container">
          {loading ? (
            <div className="loader-container text-center">
              <span className="loader"></span>
            </div>
          ) : allOrders && allOrders?.length === 0 ? (
            <div className="no-order-found">
              <b>No Order Found</b>
              <button
                className="btn text-decoration-underline"
                onClick={() => navigate("/phone-skins")}
              >
                Go to Shop
              </button>
            </div>
          ) : (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <small>Order ID</small>
                  </th>
                  <th>
                    <small>Product</small>
                  </th>
                  <th>
                    <small>Date</small>
                  </th>
                  <th>
                    <small>Status</small>
                  </th>
                  <th>
                    <small>Total</small>
                  </th>
                  <th>
                    <small>Action</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {allOrders?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <small>{item?.orderId}</small>
                      </td>
                      <td>
                        <small>{item?.productinfo}</small>
                      </td>
                      <td>
                        <small>
                          {item?.createdAt
                            ? new Date(item?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )
                            : ""}
                        </small>
                      </td>
                      <td>
                        <small
                          className={
                            item?.status === "pending"
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {item?.status}

                          {/* {item?.status === "processing" && (
                            <ReplayIcon
                              className={`icon processing-icon ${
                                orderLoader && "circle"
                              }`}
                              onClick={() =>
                                getOrderStatus(item.orderId, item.iid, item.pid)
                              }
                            />
                          )} */}
                        </small>
                      </td>
                      <td>
                        <small>{parseFloat(item?.amount).toFixed(2)}</small>
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            navigate(`/view-order/${item?.orderId}`)
                          }
                          className="view-btn"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Orders;
