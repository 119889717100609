import React from 'react'
import { Link } from 'react-router-dom'

const ReportsTabControl = ({activeTab, handleTabClick}) => {
  return (
    <div className="tab-controls border  rounded-m p-1 overflow-visible d-flex">
        <Link
            className={`tab-link ${
            activeTab === "payment" ? "active" : ""
            }`}
            aria-expanded="true"
            to="/reports?type=payment"
            onClick={() => handleTabClick("payment")}
        >
            Payment
        </Link>
        <Link
            className={`tab-link ${
            activeTab === "purchase" ? "active" : ""
            }`}
            aria-expanded="false"
            to="/reports?type=purchase"
            onClick={() => handleTabClick("purchase")}
        >
            Purchase
        </Link>
        <Link
            className={`tab-link ${
            activeTab === "ledger" ? "active" : ""
            }`}
            aria-expanded="false"
            to="/reports?type=ledger"
            onClick={() => handleTabClick("ledger")}
        >
            Ledger
        </Link>
    </div>
  )
}

export default ReportsTabControl
