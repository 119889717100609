import React, { useEffect, useState } from 'react';
import "./FooterMenu.css"
import { useNavigate } from 'react-router-dom';
import SideMenu from '../Header/SideMenu';
import NotLoginPopupModal from '../Modal/NotLoginPopupModal';
import { useDispatch, useSelector } from 'react-redux';
import AccountModal from '../Header/AccountModal';
import SupportModal from '../Header/SupportModal';
import { setActiveButton, setShowAccountModal, setShowSupportModal } from '../../redux/features/footerMenuSlice';

const FooterMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showSupportModal, showAccountModal, activeButton } = useSelector((state) => state.footerMenu);
    const { user } = useSelector((state) => state.user);
    const [sideMenu, setSideMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = (buttonName) => {
        localStorage.setItem('activeButton', buttonName);
        if(buttonName === 'more'){
            setSideMenu(!sideMenu);
        } else {
            dispatch(setActiveButton(buttonName));
            setSideMenu(false);
            if (buttonName === 'home') {
                dispatch(setShowAccountModal(false));
                dispatch(setShowSupportModal(false));
                return navigate('/');
            } else if (buttonName === 'account') {
                if(!user){
                    return setShowModal(true)
                }
                navigate('/');
                dispatch(setShowAccountModal(true));
                dispatch(setShowSupportModal(false));
            } else if (buttonName === 'support') {
                navigate('/');
                dispatch(setShowSupportModal(true));
                dispatch(setShowAccountModal(false));
            } else if (buttonName === 'reports') {
                dispatch(setShowAccountModal(false));
                dispatch(setShowSupportModal(false));
                return navigate('/reports');
            }
        }
    };
    
  return (
    <>
    <div className='footer-menu-container px-2 mb-3 d-md-none d-lg-none'>
       <div className='footer-menu px-4'>
            <div className='footer-menu-list' onClick={() => handleButtonClick('support')}>
                <div className={`footer-menu-list-svg ${activeButton === 'support' ? 'active' : ''}`}>
                    <svg width="17" height="17" fill="currentColor" className={`bi bi-telegram ${activeButton === 'support' ? 'active' : ''}`} viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                    </svg>
                </div>
                <span className='footer-menu-text'>Support</span>
            </div>
            <div className='footer-menu-list' onClick={() => handleButtonClick('account')}>
                <div className={`footer-menu-list-svg ${activeButton === 'account' ? 'active' : ''}`}>
                    <svg width="17" height="17" fill="currentColor" className={`bi bi-telegram ${activeButton === 'account' ? 'active' : ''}`} viewBox="0 0 16 16">
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4m9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                    </svg>
                </div>
                <span className='footer-menu-text'>Account</span>
            </div>
            <div className='footer-menu-list' onClick={() => handleButtonClick('home')}>
                <div className={`footer-menu-list-svg ${activeButton === 'home' ? 'active' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-house-fill ${activeButton === 'home' ? 'active' : ''}`} viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"/>
                        <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z"/>
                    </svg>
                </div>
                <span className='footer-menu-text'>Home</span>
            </div>
            <div className='footer-menu-list' onClick={() => handleButtonClick('reports')}>
                <div className={`footer-menu-list-svg ${activeButton === 'reports' ? 'active' : ''}`}>
                    <svg width="17" height="17" fill="currentColor" className={`bi bi-telegram ${activeButton === 'reports' ? 'active' : ''}`} viewBox="0 0 16 16">
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                    </svg>
                </div>
                <span className='footer-menu-text'>Reports</span>
            </div>
            <div className='footer-menu-list' onClick={() => handleButtonClick('more')}>
                <div className={`footer-menu-list-svg ${activeButton === 'more' ? 'active' : ''}`}>
                    <svg width="17" height="17" fill="currentColor" className={`bi bi-telegram ${activeButton === 'more' ? 'active' : ''}   `} viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                    </svg>
                </div>
                <span className='footer-menu-text'>More</span>
            </div>
       </div>
    </div>
    <SideMenu
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
    />
    <SupportModal
        show={showSupportModal}
        user={user}
    /> 
    <AccountModal
        show={showAccountModal}
        user={user}
    /> 
    <NotLoginPopupModal
        string="Please login to see your account details."
        show={showModal}
        onClose={()=> setShowModal(false)}
    />
    </>
  )
}

export default FooterMenu
