import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IMAGES from "../../img/image";
import "../Footer/Footer.css";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import logo from "../../img/logo2.png"


const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="container d-flex">

          <div className="col-lg-3 px-2 footer-content">
              <h4 className="fw-bold">Need help?</h4>
              <button className="bg-warning text-dark px-3 py-2 rounded-4 border-0 d-flex justify-content-between align-items-center gap-2">
                <svg width="16" height="16" fill="currentColor" className="text-dark fw-bold" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                  <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                </svg>
                <span className="fw-bold">Contact us</span>
              </button>
          </div>
          <div className="col-lg-3 px-2 footer-content">
              <h4 className="fw-bold">Quick Links</h4>
              <div className="d-flex gap-2">
                <button
                  className="bg-white px-3 py-2 rounded-4 border-0 d-flex justify-content-between align-items-center gap-2"
                  onClick={()=>{navigate('/login')}}
                >
                  <span className="text-dark fw-bold">Login</span>
                  <svg width="16" height="16" fill="currentColor" className="text-dark fw-bold" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                  </svg>
                </button>
                <button
                  className="bg-white px-3 py-2 rounded-4 border-0 d-flex justify-content-between align-items-center gap-2"
                  onClick={()=>{navigate('/register')}}
                >
                  <span className="text-dark fw-bold">Register</span>
                  <svg width="16" height="16" fill="currentColor" className="text-dark fw-bold" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                  </svg>
                </button>
              </div>
          </div>
          <div className="col-lg-3 px-2 footer-content">
              <h4 className="fw-bold">Stay updated with us</h4>
              <div className="d-flex gap-2">
                <InstagramIcon className="social-icon fs-1 rounded-pill p-2 bg-white text-dark" onClick={()=>{window.location.href = "https://www.instagram.com/topupplayground/"}}/>
                <WhatsAppIcon className="social-icon fs-1 rounded-pill p-2 bg-white text-dark" onClick={()=>{window.location.href = "https://wa.me/917758007845"}}/>
                <EmailIcon className="social-icon fs-1 rounded-pill p-2 bg-white text-dark" onClick={()=>{window.location.href = "mailto:topupplaygroundofficial.com"}}/>
              </div>
          </div>
          
        </div>
      </div>
      <div className="bg-light footer-container-bottom py-3">
          <div className="container footer-container-bottom-content">
            <div className="d-flex justify-content-left align-items-center gap-2">
                <span className="copyright">© Copyright <span className="fw-bold">Topup Playground</span></span>
                <span className="dot">•</span> {/* Dot separator */}
                <span className="copyright">
                  <span>Developed by</span>
                  <Link
                    target="_blank"
                    className='text-danger'
                    to="https://aashirdigital.com/"
                  >
                    ~@aashirdigital
                  </Link>
                </span>
                <span className="dot">•</span>
                <span className="copyright">
                  <Link
                    target="_blank"
                    className='text-dark'
                    to="/terms"
                  >
                    Terms and Conditions
                  </Link>
                </span>
                <span className="dot">•</span>
                <span className="copyright">
                  <Link
                    target="_blank"
                    className='text-dark'
                    to="/privacy-policy"
                  >
                    Privary Policy
                  </Link>
                </span>
                <span className="dot">•</span>
                <span className="copyright">
                  <Link
                    target="_blank"
                    className='text-dark'
                    to="/refund-policy"
                  >
                    Refund Policy
                  </Link>
                </span>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
