import React from "react";
import { Modal } from "antd";
import './SinglePaymentModalReport.css'; 

const DateRangeFilter = ({
  onClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  clearFilters,
  handleDateChange
}) => {
  return (
    <Modal
      title="Filter"
      open={true}
      onOk={handleDateChange}
      onCancel={clearFilters}
      className="date-range-filter-modal"
      footer={null} // Custom footer to be used
    >
      <hr />
      <div className="date-range-filter-container">
        <div>
          <label>Start Date</label>
          <input
            type="date"
            className="form-control w-100 mt-2"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label>End Date</label>
          <input
            type="date"
            className="form-control w-100 mt-2"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="date-range-filter-footer mt-3">
        <button className="cancel-btn" onClick={clearFilters}>
          CLEAR FILTER
        </button>
        <button className="apply-btn" onClick={handleDateChange}>
          APPLY FILTER
        </button>
      </div>
    </Modal>
  );
};

export default DateRangeFilter;
