import axios from "axios";
import { setProducts } from "../redux/features/dataSlice";

const getAllProducts = async (dispatch) => {
  try {
    const res = await axios.get("/api/product/get-all-products");
    if (res.data.success) {
      const allProducts = res.data.data;
      const sortedProducts = [...allProducts].sort(
        (a, b) => parseInt(a.seq) - parseInt(b.seq)
      );
      dispatch(setProducts(sortedProducts));
    }
  } catch (error) {
    console.log(error);
  }
};

export default getAllProducts;
