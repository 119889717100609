import React, { useEffect } from 'react';
import contactPDF from '../img/contact.pdf'; // Importing the PDF

const ContactUs = () => {
  useEffect(() => {
    window.location.href = contactPDF; // Use the imported PDF
  }, []);

  return (
    <div>
      Loading...
    </div>
  );
}

export default ContactUs;
