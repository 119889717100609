import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../utils/userDataService";
import { setUser } from "../redux/features/userSlice";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import "./ProductInfo.css";
import { submitPaymentForm } from "./ProductInfoMobile/submitPaymentForm";

const ProductInfo = () => {
  const { user } = useSelector((state) => state.user);
  const { balance } = useSelector((state) => state.data);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [allowUser, setAllowUser] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("UPI");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  const [originalPrice, setOriginalPrice] = useState(null);
  //! API BASED
  const [orderId, setOrderId] = useState("");
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [loader, setLoader] = useState(false);
  const [gateway, setGateway] = useState("BARCODE");

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setOriginalPrice(pf);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setOriginalPrice(pf);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-product-by-name",
        {
          name: params.name,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultOriginalPrice = res.data.data?.cost?.[0]?.profit;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
        setOriginalPrice(defaultOriginalPrice);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;

    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setCheckLoading(true);
      const object = {
        region: "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: "13",
      };
      const res = await axios.post("/api/payment/get-role", object, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setCheckLoading(false);
        if (product?.use === res.data.use) {
          setAllowUser(true);
        } else {
          setAllowUser(false);
        }
      } else {
        message.error(res.data.message);
        setCheckLoading(false);
      }
    } catch (error) {
      console.log(error);
      setCheckLoading(false);
    }
  }

  //* ================================= ORDER SYSTEM ==========================================

  function checkPlaceOrder(e) {
    if (product?.playerCheckBtn === "yes") {
      if (playerCheck === null) {
        return message.error("Check username");
      }
    }
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
    } else if (product.api === "yes" && product?.apiName === "indianSmart") {
      const userConfirmed = window.confirm("is your instagram account public?");
      if (!userConfirmed) {
        return message.error("Make your insta account public");
      }
      if (userId === "") {
        return message.error("Enter Link");
      }
    } else if (product.api === "yes" && product?.apiName === "primeSmm") {
      if (userId === "") {
        return message.error("Enter Link");
      }
      const userConfirmed = window.confirm("is your instagram account public?");
      if (!userConfirmed) {
        return message.error("Make your insta account public");
      }
    } else if (product?.apiName === "moogold") {
      if (product?.fields?.length === 1) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      } else if (product?.fields?.length === 2) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeSmileUpi(e);
          } else if (gateway === "PAYU") {
            handleSmileOneUpiOrder();
          }
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeMoogoldUpi(e);
          } else if (gateway === "PAYU") {
            handleMoogoldUpiOrder();
          }
        } else {
          handleMoogoldWalletOrder(e);
        }
      } else if (product?.apiName === "indianSmart") {
        if (mode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeIndianSmartUpiOrder(e);
          } else if (gateway === "PAYU") {
            handleIndianSmartUpiOrder();
          }
        } else {
          handleIndianSmartWalletOrder(e);
        }
      } else if (product?.apiName === "primeSmm") {
        if (mode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodePrimeSmmUpiOrder(e);
          } else if (gateway === "PAYU") {
            handlePrimeSmmUpiOrder();
          }
        } else {
          handlePrimeSmmWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        if (gateway === "BARCODE") {
          handleBarcodeUpiOrder(e);
        } else if (gateway === "PAYU") {
          handleUpiOrder();
        }
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // =========== PAYU
  //YOKCASH
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://www.topupplayground.com/api/yokcash/check-yokcash-upi-order?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "%" +
          zoneId.trim() +
          "%" +
          productId +
          "%" +
          product?.name,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "%" +
          zoneId.trim() +
          "%" +
          productId +
          "%" +
          product?.name,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/yokcash/place-yokcash-from-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/");
        setLoading(false);
        setLoader(false);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  // SMILE
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/smile/success",
        // failure_URL: "http://localhost:8080/api/smile/failure",
        success_URL: "https://topupplayground.com/api/smile/success",
        failure_URL: "https://topupplayground.com/api/smile/failure",
        udf1: product?.region + "@" + originalPrice, // region + originalprice
        udf2: productId, // productId
        udf3: userId, // userId
        udf4: zoneId, // zoneId
        udf5: amount, // amount
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    setLoader(true);
    setLoading(true);
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        name: user?.fname,
        price: selectedPrice,
        originalPrice: originalPrice,
      };

      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    } finally {
      setLoader(false);
      setLoading(false);
    }
  };
  const handleBarcodeSmileUpi = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      const response = await axios.post("/api/smile/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // MOOOGOLD
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/moogold/success",
        // failure_URL: "http://localhost:8080/api/moogold/failure",
        success_URL: "https://topupplayground.com/api/moogold/success",
        failure_URL: "https://topupplayground.com/api/moogold/failure",

        // product_details: product?.categoryId + "@" + product?.gameName,
        udf1: product?.gameName + "@" + originalPrice, // product gameName
        udf2: productId, // productId
        udf3: userId,
        udf4: zoneId,
        udf5: amount,
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: product?.api,
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.categoryId + "@" + product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
        originalPrice: originalPrice,
      };

      setLoader(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
        setLoader(false);
      } else {
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }
  async function handleBarcodeMoogoldUpi(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/moogold/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      const response = await axios.post("/api/moogold/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // INDIANSMART
  async function handleIndianSmartUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/indian/success",
        // failure_URL: "http://localhost:8080/api/indian/failure",
        success_URL: "https://topupplayground.com/api/indian/success",
        failure_URL: "https://topupplayground.com/api/indian/failure",
        udf1: userId,
        udf2: productId,
        udf3: amount,
        udf4: originalPrice,
        udf5: "",
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {}
  }
  async function handleIndianSmartWalletOrder(e) {
    e.preventDefault();
    try {
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error("Balance is less for this order");
      }
      const orderObject = {
        orderId: orderId,
        price: selectedPrice,
        pname: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId,
        productid: productId,
        amount: amount,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/indian/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoader(false);
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleBarcodeIndianSmartUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/indian/status?orderId=${orderId}&link=${userId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/indian/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // PRIME SMM
  async function handlePrimeSmmUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/indian/success",
        // failure_URL: "http://localhost:8080/api/indian/failure",
        success_URL: "https://topupplayground.com/api/prime/success",
        failure_URL: "https://topupplayground.com/api/prime/failure",
        udf1: userId,
        udf2: productId,
        udf3: amount,
        udf4: originalPrice,
        udf5: "",
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {}
  }
  async function handlePrimeSmmWalletOrder(e) {
    e.preventDefault();
    try {
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error("Balance is less for this order");
      }
      const orderObject = {
        orderId: orderId,
        price: selectedPrice,
        pname: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId,
        productid: productId,
        amount: amount,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/prime/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoader(false);
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleBarcodePrimeSmmUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/prime/status?orderId=${orderId}&link=${userId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/prime/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // MANUAL
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/manual/success",
        // failure_URL: "http://localhost:8080/api/manual/failure",
        success_URL: "https://topupplayground.com/api/manual/success",
        failure_URL: "https://topupplayground.com/api/manual/failure",
        udf1: userId, // userId
        udf2: amount, // amount
        udf3: originalPrice,
        udf4: "",
        udf5: "",
      };

      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoader(false);
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleBarcodeUpiOrder = async (e) => {
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/manual/status?orderId=${orderId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/manual/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Layout>
      <div className="p-info-container area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="pro-img">
          <img src={`https://topupplayground.com/${product?.image}`} alt="" />
        </div>
        <div className="pro-content">
          <h2>{product?.name}</h2>
          <h6>⚡Instant Recharge⚡</h6>
        </div>
      </div>
      <div className="package-details">
        <div className="package-container">
          {product?.cost?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setAmount(item.amount);
                  setPriceAndId(item.amount);
                }}
                key={index}
                className={`amount ${amount === item?.amount && "active"}`}
              >
                <span>
                  <img src={item?.pimg} alt="" />
                  <small>{item.amount}</small>
                </span>
              </div>
            );
          })}
        </div>
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}
        <div className="order-info">
          <div className="pack-info">
            <span>Order Information</span>
            {product?.apiName === "smileOne" ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={product?.tagOne ? product?.tagOne : "USER ID"}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder={
                    product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"
                  }
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                {product?.playerCheckBtn === "yes" && (
                  <button className="p-check-btn" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
                <span className="text-danger">
                  {error &&
                    "First Check your username by clicking check button"}
                </span>
                {playerCheck && allowUser && (
                  <span
                    className="playername text-success"
                    style={{ border: "1px solid green" }}
                  >
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                )}
                {playerCheck && !allowUser && (
                  <span
                    className="playername text-danger"
                    style={{ border: "1px solid red" }}
                  >
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                )}
              </>
            ) : product?.apiName === "moogold" ? (
              product?.fields?.length === 2 &&
              typeof product.servers === "object" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                      placeholder={
                        product?.tagOne ? product?.tagOne : "USER ID"
                      }
                    />
                  </div>
                  <select
                    onChange={(e) => setZoneId(e.target.value)}
                    className="player-tag"
                    name="zoneId"
                  >
                    <option value="">Select Server</option>
                    {Object.entries(product?.servers).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </>
              ) : product?.fields.length === 2 &&
                typeof product.servers === "string" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={
                        product?.tagOne ? product?.tagOne : "USER ID"
                      }
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder={
                      product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"
                    }
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  {product?.playerCheckBtn === "yes" && (
                    <button className="p-check-btn" onClick={handleCheckPlayer}>
                      Check
                      {loading && (
                        <div
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  )}
                  <span className="text-success">
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                </>
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={
                    product?.tagOne ? product?.tagOne : "(  USER ID  )"
                  }
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )
            ) : product?.apiName === "indianSmart" ? (
              <div className="d-flex align-items-center">
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={product?.tagOne ? product?.tagOne : "ENTER LINK"}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
            ) : (
              <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tagOne}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            )}
          </div>

          <div className="mt-4 pack-info">
            <div className="title">
              <span>Select Payment Mode</span>
            </div>
            <div className="payment">
              <div
                onClick={() => setMode("UPI")}
                className={`upi ${mode === "UPI" && "active"}`}
              >
                {/* <img src={IMAGES.upi} alt="" /> */}
                <h4 className="m-0">UPI</h4>
              </div>
              <div
                onClick={() => setMode("WALLET")}
                className={`wallet ${mode === "WALLET" && "active"}`}
              >
                <div>
                  <AccountBalanceWalletIcon className="icon" />
                  <span className="ms-2">{user && <b>Rs. {balance}</b>}</span>
                </div>
                <h4 className="m-0">Wallet</h4>
              </div>
            </div>
          </div>

          <div className="mt-4 pack-info">
            <div className="title">
              <span>Total</span>
              <div className="price ">
                {selectedPrice !== null ? (
                  <h3 className="m-0 mt-3">
                    <b>Rs. {selectedPrice}</b>
                  </h3>
                ) : (
                  "Select an amount to see the price"
                )}
              </div>
            </div>
            {!user ? (
              <button
                className="p-check-btn"
                onClick={() => navigate("/login")}
              >
                Please Login First
              </button>
            ) : product?.stock === "No" ? (
              <button className="p-check-btn" style={{ opacity: "0.7" }}>
                Out of Stock
              </button>
            ) : product?.apiName === "smileOne" && !allowUser ? (
              <button disabled={true} className="p-check-btn">
                This ID is not allowed
              </button>
            ) : product?.apiName === "smileOne" && playerCheck !== null ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div
                    className="ms-2 spinner-grow spinner-grow-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            ) : product?.apiName === "moogold" &&
              product?.fields?.length === 2 &&
              typeof product?.servers === "string" ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div
                    className="ms-2 spinner-grow spinner-grow-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            ) : (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div
                    className="ms-2 spinner-grow spinner-grow-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}

        <div className="pro-desc-container">
          <div className="pro-desc">
            <span>{product?.desc}</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
