import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Success.css'; // Make sure to create and style this CSS file

const Success = () => {
  const navigate = useNavigate();

  const handleContinueShopping = () => {
    navigate('/'); // Redirect to home page or wherever you'd like
  };

  return (
    <div className="success-container">
      <CheckCircleOutlined className="success-icon success-animated" />
      <h1 className="success-message">Payment Successful!</h1>
      <p className="success-description">
        Thank you for your payment. Your transaction has been completed successfully.
      </p>
      <Button type="primary" onClick={handleContinueShopping} className="success-button center">
        Continue Ordering
      </Button>
    </div>
  );
};

export default Success;
