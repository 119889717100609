import axios from "axios";
import CryptoJS from "crypto-js";
import { setBalance } from "../redux/features/dataSlice";
import { setUser } from "../redux/features/userSlice";

const getUserData = async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/user/getUserData",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (res.data.success) {
      const { user, id, key: encryptedKey, iv: encryptedIv } = res.data.data;
      const key = CryptoJS.enc.Hex.parse(encryptedKey);
      const iv = CryptoJS.enc.Hex.parse(encryptedIv);
      const decryptedBalance = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Hex.parse(id) },
        key,
        { iv: iv }
      ).toString(CryptoJS.enc.Utf8);
      dispatch(setBalance(decryptedBalance));
      dispatch(setUser(user));
    } else {
      localStorage.removeItem("token");
      dispatch(setUser(null));
    }
  } catch (error) {
    console.log(error);
  }
};

export default getUserData;
