import React from "react";
import "./InstructionModal.css";

const InstructionModal = ({ showInstructions, setShowInstructions, product }) => {

  const closeModal = (e) => {
    e.stopPropagation();
    setShowInstructions(false);
  };

  return (
    <div
      className={`instructions-container d-lg-none d-md-none ${
        showInstructions ? "active" : ""
      }`}
      onClick={closeModal}
    >
      <div
        className={`instructions-content ${showInstructions ? "active" : ""} p-3`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mt-2 py-1 w-100">
          <h1 className="mb-4">Follow Instruction</h1>
          {product?.desc?.split("\n").map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstructionModal;
