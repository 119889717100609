import React, { useState } from "react";
import "./PrivacyPolicyMobile.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../components/Header/SideMenu";
import HeroSvg from "../../components/Layout/HeroSvg";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import { ChevronLeftSvg, ListSvg } from "../../components/AllSvgModulesExport/LoginSvg";
import Layout from "../../components/Layout/Layout";

const TermsAndConditionMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);

  const onClose = () => {
    dispatch(setActiveButton("home"));
    navigate("/");
  };

  return (
    <>
    <Layout>
      <div className="account-modal-container privacy-policy d-lg-none d-md-none">
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <ChevronLeftSvg />
            </div>
            <h2 className="mb-0">Terms & Conditions</h2>
          </div>
          <div className="top-right-content box-2 center" onClick={() => setSideMenu(!sideMenu)}>
            <ListSvg />
          </div>
        </div>

        <div className="privacy-policy-container px-3">
          <div className="privacy-policy-content px-3 mb-5">
            <p className="mt-3 text-danger fw-bold">
              Please read the following terms and conditions carefully before using our website and services:
            </p>
            <h5 className="mt-4">Acceptance of Terms:*</h5>
            <p>
              - By accessing or using our website and services, you agree to comply
              with and be bound by these terms and conditions.
            </p>
            <h5 className="mt-4">User Eligibility:*</h5>
            <p>
              - Users must be at least [13] years old to use our services. By using
              our services, you confirm that you meet this age requirement.
            </p>
            <h5 className="mt-4">Account Information:*</h5>
            <p>
              - Users are responsible for maintaining the confidentiality of their
              account information, including login credentials. Any activity
              conducted under your account is your responsibility.
            </p>
            <h5 className="mt-4">In-Game Purchases:*</h5>
            <p>
              - In-game purchases are subject to our refund policy. Users are solely
              responsible for all transactions made through their accounts.
            </p>
            <h5 className="mt-4">Intellectual Property:*</h5>
            <p>
              - All content on our website, including but not limited to text,
              graphics, logos, and images, is the property of [Your Company Name]
              and is protected by intellectual property laws.
            </p>
            <h5 className="mt-4">Prohibited Activities:*</h5>
            <p>
              - Users must not engage in any unlawful or prohibited activities,
              including but not limited to hacking, data mining, or any actions that
              could disrupt the integrity of our services.
            </p>
            <h5 className="mt-4">Third-Party Links:*</h5>
            <p>
              - Our website may contain links to third-party websites. We are not
              responsible for the content or practices of these websites and
              encourage users to review their terms and conditions.
            </p>
            <h5 className="mt-4">Disclaimer of Warranties:*</h5>
            <p>
              - Our services are provided "as is" without any warranty. We do not
              guarantee the accuracy, completeness, or reliability of our services.
            </p>
            <h5 className="mt-4">Limitation of Liability:*</h5>
            <p>
              - Topup Playground and its affiliates are not liable for any direct,
              indirect, incidental, or consequential damages resulting from the use
              of our services.
            </p>
            <h5 className="mt-4">Modification of Terms:*</h5>
            <p>
              - We reserve the right to modify these terms and conditions at any
              time. Users will be notified of significant changes.
            </p>
            {/* <h5 className="mt-5">Governing Law:*</h5>
            <p>
              - These terms and conditions are governed by the laws of Maharashtra
              Jurisdiction. Any disputes shall be resolved in the courts of
              Maharashtra Jurisdiction.
            </p> */}
            <h5 className="mt-4">Contact Information:*</h5>
            <p>
              - For inquiries related to these terms and conditions, please contact
              us at topupplaygroundofficial@gmail.com.
            </p>
            <p>
              <i>
                By using our website and services, you agree to abide by these terms
                and conditions. If you do not agree with any part of these terms,
                please refrain from using our services.
              </i>
            </p>
          </div>
        </div>
      </div>
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </Layout>
    </>
  );
};

export default TermsAndConditionMobile;
