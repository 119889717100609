import { message, Modal } from "antd";
import AdminViewOrder from "./AdminViewOrder";
import AdminLayout from "./components/AdminLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const AdminUsers = () => {
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderId, SetOrderId] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedMonth, setSelectedMonth] = useState("");

  // Modal visibility states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false); // Filter modal visibility for mobile

  function handleClearFilter() {
    setAllUser(originalUserData);
    SetOrderId("");
    setSearchEmail("");
    setSelectedType("");
    setSelectedDate("");
    setSelectedMonth("");
  }

  const getAllOrders = async (date = null) => {
    setLoading(true);
    try {
      const query = date ? `?date=${date}` : ""; // Append date if provided
      const res = await axios.get(`/api/admin/admin-get-all-orders${query}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data.reverse()); // Reverse to show latest orders first
        setOriginalUserData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Failed to load orders");
    } finally {
      setIsFilterModalVisible(false);
      setLoading(false);
    }
  };

  const handleViewOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedOrderId(null);
  };

  const handleSearch = () => {
    if (originalUserData) {
      const filteredUsers = originalUserData.filter((order) => {
        const orderIdMatch = order.orderId && order.orderId.includes(orderId);
        const emailMatch =
          order.email &&
          order.email.toLowerCase().includes(searchEmail.toLowerCase());
        const typeMatch =
          selectedType === ""
            ? true
            : order.api &&
              order.api === (selectedType === "yes" ? "yes" : "no");
        const dateMatch =
          order.createdAt &&
          (!selectedDate ||
            new Date(order.createdAt).toISOString().split("T")[0] ===
              new Date(selectedDate).toISOString().split("T")[0]);
        const monthMatch =
          order.createdAt &&
          (!selectedMonth ||
            new Date(order.createdAt).getMonth() === Number(selectedMonth) - 1);

        return (
          orderIdMatch && emailMatch && typeMatch && dateMatch && monthMatch
        );
      });
      setAllUser(filteredUsers);
    }
  };

  useEffect(() => {
    // Fetch orders based on selectedDate or current date by default
    const date = selectedDate;
    getAllOrders(date);
  }, [selectedDate]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [orderId, searchEmail, selectedType, selectedDate, selectedMonth]);

  if (loading)
    return (
      <AdminLayout>
        <div className="w-100 center">
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </AdminLayout>
    );

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h3 className="m-0">Orders</h3>
          <h6 className="mb-0">Total Orders - {allUser?.length}</h6>
        </div>
        <hr />

        {/* Mobile Filter Button */}
        <div className="d-block d-md-none mb-3">
          <button
            className="btn btn-primary w-100"
            onClick={() => setIsFilterModalVisible(true)}
          >
            Filters
          </button>
        </div>

        {/* Desktop Filter Inputs */}
        <div className="d-none d-md-flex gap-3 mb-3">
          <input
            type="text"
            placeholder="Order ID"
            className="form-control"
            value={orderId}
            onChange={(e) => SetOrderId(e.target.value)}
          />
          <input
            type="text"
            placeholder="Email"
            className="form-control"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
          <select
            className="form-control"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option value="">Type</option>
            <option value="yes">API</option>
            <option value="no">Manual</option>
          </select>
          <input
            type="date"
            className="form-control"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
          <select
            className="form-control"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">Select Month</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
          <button
            className="btn btn-secondary w-100"
            onClick={handleClearFilter}
          >
            Clear Filters
          </button>
        </div>

        {/* Mobile Filter Modal */}
        <Modal
          title="Filters"
          visible={isFilterModalVisible}
          onCancel={() => setIsFilterModalVisible(false)}
          footer={null}
          width={300}
          centered
        >
          <div className="d-flex flex-column gap-3">
            <input
              type="text"
              placeholder="Order ID"
              className="form-control"
              value={orderId}
              onChange={(e) => SetOrderId(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              className="form-control"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <select
              className="form-control"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Type</option>
              <option value="yes">API</option>
              <option value="no">Manual</option>
            </select>
            <input
              type="date"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <select
              className="form-control"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
            <button
              className="btn btn-secondary w-100"
              onClick={handleClearFilter}
            >
              Clear Filters
            </button>
          </div>
        </Modal>

        <div className="overflow-auto bg-light">
          <table className="table user-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Product</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Email</th>
                <th>Service ID</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {allUser &&
                allUser?.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <small>{user?.orderId}</small>
                    </td>
                    <td>
                      <small>{user?.api === "no" ? "Manual" : "Api"}</small>
                    </td>
                    <td className="no-wrap">
                      <small>{user?.productinfo}</small>
                    </td>
                    <td className="fw-bold">{user?.amount}₹</td>
                    <td className="no-wrap">
                      <small>
                        {new Date(user?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </small>
                    </td>
                    <td>
                      <small>{user?.email}</small>
                    </td>
                    <td className="no-wrap">
                      <small>
                        {user?.mid && `mid: ${user?.mid}`}
                        {user?.sid && `sid: ${user?.sid}`}
                        {user?.iid && `iid: ${user?.iid}`}
                        {user?.pid && `pid: ${user?.pid}`}
                      </small>
                    </td>
                    <td>
                      <small
                        className={`
                          ${user?.status === "pending" && "text-warning"}
                          ${user?.status === "success" && "text-success"}
                          ${user?.status === "failed" && "text-danger"}
                          ${user?.status === "processing" && "text-warning"}
                          ${user?.status === "refunded" && "text-primary"}
                        `}
                      >
                        {user?.status}
                      </small>
                    </td>
                    <td align="center">
                      <small className="pointer">
                        <RemoveRedEyeIcon
                          onClick={() => handleViewOrder(user?.orderId)}
                        />
                      </small>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Modal for viewing order details */}
        <Modal
          title={`Order Details`}
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          <AdminViewOrder
            orderId={selectedOrderId}
            onClose={handleCloseModal}
          />
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default AdminUsers;
