import React from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setShowAccountModal, setShowSupportModal } from '../../redux/features/footerMenuSlice';

const NotLoginPopupModal = ({show, onClose, string}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLoginClick = ()=>{
    dispatch(setShowSupportModal(false));
    dispatch(setShowAccountModal(false));
    navigate('/login')
  }
    if (!show) {
        return null;
      }
  return (
    <div className="account-modal z-index" onClick={onClose}>
      <div className="account-modal-content pt-5 pb-4 px-4" onClick={(e)=> e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <p className='mb-0'>{string}</p>
        {location.pathname !== "/login" && <div className='mt-4 d-flex justify-content-end align-items-center' onClick={onLoginClick}>
            <p className='mb-0 text-primary'>login here</p>
            <svg
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-up-right-square text-primary ms-2"
                viewBox="0 0 16 16"
            >
                <path
                fillRule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"
                />
            </svg>
        </div>}
      </div>
    </div>
  )
}

export default NotLoginPopupModal
