import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showSupportModal: false,
    showSearchModal: false,
    showAccountModal: false,
    activeButton: 'home',
  };

const footerMenuSlice = createSlice({
    name: 'footerMenu',
    initialState,
    reducers: {
      setActiveButton: (state, action) => {
        state.activeButton = action.payload;
      },
      setShowSupportModal: (state, action) => {
        state.showSupportModal = action.payload;
      },
      setShowAccountModal: (state, action) => {
        state.showAccountModal = action.payload;
      },
      setShowSearchModal: (state, action) => {
        state.showSearchModal = action.payload;
      },
    },
  });

  export const { setActiveButton, setShowSupportModal, setShowAccountModal, setShowSearchModal } = footerMenuSlice.actions;

  export default footerMenuSlice.reducer;