import React, { useState } from 'react';
import SinglePaymentModalReport from './SinglePaymentModalReport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { message } from 'antd';

const PaymentReports = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "text-warning";
      case "processing":
        return "text-warning";
      case "success":
        return "text-success";
      case "Success":
        return "text-success";
      case "rejected":
        return "text-danger";
      case "failure":
        return "text-danger";
      case "failed":
        return "text-danger";
      case "refunded":
        return "text-primary";
      default:
        return "";
    }
  };

  const handleItemClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const copyToClipboard = (event, text) => {
    event.stopPropagation(); 
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Payment ID copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy!');
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
      <div
        key={item?.orderId}
        className="list w-100 d-flex justify-content-between align-items-center mt-2 p-2 border border-bottom rounded-4 bg-light"
        onClick={handleItemClick}
      >
        <div className="center gap-2">
          <div className="add-icon-box">+</div>
          <div className="d-flex justify-content-center align-items-start flex-column">
            <span className="add-money">
              {item?.productInfo === "noSponsor" ? ("Add Money").toUpperCase() : (item?.productInfo)?.toUpperCase()}
            </span>
            <span className="payment-order-id center gap-1" onClick={(event) => copyToClipboard(event, `Payment Id: ${item?.orderId}`)}>
              <ContentCopyIcon className='fs-6'/><span>{item?.orderId}</span> 
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-end flex-column">
          <div className='center gap-2'>
            <h4 className="mb-0 payment-amount">
              ₹{parseFloat(item?.amount).toFixed(2)}
            </h4>
            <svg width="16" height="16" fill="currentColor" className={`${getStatusClass(item?.status?.toLowerCase())}`}  viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
            </svg>
          </div>
          <div className="center gap-2">
            <span className="payment-date no-wrap">
              {new Date(item?.createdAt).toLocaleString("default", {
                day: "2-digit",
                month: "short",
              })}
            </span>
            <span className={`${getStatusClass(item?.status?.toLowerCase())} payment-status`}>
              {item?.status?.toLowerCase()}
            </span>
          </div>
        </div>
      </div>

      {showModal && <SinglePaymentModalReport onClose={handleCloseModal} item={item} />}
    </>
  );
};

export default PaymentReports;
