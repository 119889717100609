import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminQueries.css";
import axios from "axios";
import { message } from "antd";
import { Tooltip } from "antd";

const AdminPayments = () => {
  const [payments, setPayments] = useState(null);
  const [allPayments, setAllPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [searchEmail, setSearchEmail] = useState(null);

  function handleClearFilter() {
    setAllPayments(payments);
    setOrderId("");
    setSearchEmail("");
  }

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/payment/get-all-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllPayments(res.data.data);
        setPayments(res.data.data?.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPayments();
    // eslint-disable-next-line
  }, []);

  //! Search
  const handleSearch = () => {
    if (payments) {
      // When both searchEmail and orderId are empty, show all payments
      if (orderId === "" || searchEmail === "") {
        setAllPayments(payments);
        return;
      }
      const filteredPayments = payments.filter((payment) => {
        const orderIdMatch = orderId && payment?.orderId?.includes(orderId);
        const emailMatch =
          searchEmail &&
          payment?.email?.toLowerCase().includes(searchEmail.toLowerCase());
        return emailMatch || orderIdMatch;
      });

      setAllPayments(filteredPayments);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [orderId, searchEmail]);

  return (
    <AdminLayout>
      <div className="page-title px-3">
        <h3 className="m-0">Payments</h3>
        <h6>Total Payments - {payments?.length}</h6>
      </div>
      <hr />
      <div className="admin-queries">
        <div className="tools mb-lg-4">
          <div className="form-fields">
            <input
              onChange={(e) => setOrderId(e.target.value)}
              className="py-2"
              name="orderId"
              type="text"
              placeholder="Search by Order ID"
            />
          </div>
          <div className="form-fields">
            <input
              onChange={(e) => setSearch(e.target.value)}
              className="py-2"
              name="search"
              type="text"
              placeholder="Search by email"
            />
          </div>
        </div>
        <table className="table query-table">
          <thead>
            <tr>
              <th>TxnId</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Mobile</th>
              <th>Date</th>
              <th>P Name</th>
              <th>Type</th>
              <th>Status</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loader-container text-center">
              <span className="loader"></span>
            </div>
          ) : (
            <tbody>
              {allPayments &&
                allPayments
                  ?.filter((item) =>
                    item?.email.includes(search?.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>
                            {item?.orderId}
                          </small>
                        </td>
                        <td>
                          <small>{item?.email}</small>
                        </td>
                        <td>
                          <span className="fw-bold">{item?.amount}₹</span>
                        </td>
                        <td>
                          <small>{item?.mobile}</small>
                        </td>
                        <td className="no-wrap">
                          <small>
                            {new Date(item?.createdAt).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                          </small>
                        </td>
                        <td className="no-wrap ">
                          <small>{item?.productInfo}</small>
                        </td>
                        <td>{item?.paymentType}</td>
                        <td
                          className={`${
                            (item?.status)?.toLowerCase() === "success"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {(item?.status?.toLowerCase())}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          )}
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
