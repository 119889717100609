import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import SliderText from "../components/Home/SliderText";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Testimonials from "../components/Home/Testimonial";
import Games from "../components/Games";
import News from "../components/News";
import IMAGES from "../img/image";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Home.css";
import { message } from "antd";
import NavigationButtons from "../components/NavigationButtons";
import GamesForMobile from "../components/GamesForMobile";
import Header from "../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";

import {
  setShowAccountModal,
  setShowSupportModal,
} from "../redux/features/footerMenuSlice";
import HomePageMobileAlert from "./HomePageMobileAlert";
import { setShowPopup } from "../redux/features/alertSlice";

const Home = () => {
  const dispatch = useDispatch();
  const { showPopup } = useSelector((state) => state.alerts);
  const [popupData, setPopupData] = useState({
    image: "",
    title: "",
    desc: "",
    link: "",
    display: ""
  });

  useEffect(() => {
    const savedActiveButton = localStorage.getItem("activeButton");
    if (savedActiveButton === "account") {
      dispatch(setShowAccountModal(true));
    } else if (savedActiveButton === "support") {
      dispatch(setShowSupportModal(true));
    }
  }, [dispatch]);

  const getNoti = async () => {
    try {
      const res = await axios.get("/api/noti/get-noti");
      if (res.data.success) {
        const { image, title, desc, link, display } = res.data.data[0];
        setPopupData({ image, title, desc, link, display });
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNoti();
    // eslint-disable-next-line
  }, []);

  const handleClosePopup = () => {
    dispatch(setShowPopup(false)); 
    localStorage.setItem("giveaway", "false");
  };

  return (
    <Layout>
      {(showPopup && popupData?.display === "yes") && (
        <div className="popup-container d-none d-lg-flex d-md-flex">
          <div>
            <CancelIcon onClick={handleClosePopup} className="icon" />
          </div>
          <Link target="_blank" to={popupData?.link}>
            <img src={popupData?.image} alt="popup-img" />
          </Link>
        </div>
      )}
      {(showPopup && popupData?.display === "yes") && <HomePageMobileAlert popupData={popupData} onClose={handleClosePopup}/>}
      <div className="d-block d-lg-none d-md-none">
        <Header />
      </div>
      <HeroSection/>
      <NavigationButtons />
      <div className="d-none d-lg-block d-md-block desktop-games-container">
        <Games title={"Popular Games Top-Up"} />
      </div>
      <div className="d-lg-none d-md-none">
        <GamesForMobile title={"Popular Games Top-Up"} />
      </div>
    </Layout>
  );
};

export default Home;
