import React, { useEffect, useState } from "react";
import "./AccountModal.css";
import HeroSvg from "../Layout/HeroSvg";
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import {
  setActiveButton,
  setShowAccountModal,
} from "../../redux/features/footerMenuSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { PasswordSvg, SponsorSvg } from "../AllSvgModulesExport/LoginSvg";
import { ChevronRightIcon, LogoutIcon, QuestionIcon, RupeesIcon, WalletIcon } from "./AccountModalSvg";
import { setUser } from "../../redux/features/userSlice";

const AccountModal = ({ show, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  const [tab, setTab] = useState(0);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setForm(user);
    }
  }, [user]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const getFirstLetter = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  if (!show) {
    return null;
  }

  const onClose = () => {
    dispatch(setShowAccountModal(false));
    dispatch(setActiveButton("home"));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const res = await axios.post("/api/user/user-profile-update", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setForm({ ...form, password: "" });
        setTab(0);
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  const states = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"];

  const handleButtonClick = (path)=>{
    dispatch(setShowAccountModal(false));
    dispatch(setActiveButton("more"));
    navigate(path)
  }

  const handleLogout = () => {
    const userConfirmed = window.confirm('Are you sure you want to logout?');
    
    if (userConfirmed) {
      dispatch(setUser(null));
      localStorage.clear();
      message.success("Logout Successful");
      navigate('/');
      dispatch(setActiveButton('home'));
      dispatch(setShowAccountModal(false));
    }
  };

  return (
    <>
      <div className={`account-modal-container d-lg-none d-md-none `}>
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </div>
            <h2 className="mb-0">My Account</h2>
          </div>
          <div
            className="top-right-content box-2 center"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <svg
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-list text-white"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </div>
        </div>

        <div className="account-modal-bottom-container px-3">
          <div className="account-modal-bottom-content px-3 pb-3">
            <div className="account-modal-bottom-profile-content center flex-column">
              <div className="profile-circle center">
                <span className="text-white">
                  {getFirstLetter(user?.fname)}
                </span>
              </div>
              <h2 className="mb-1">{capitalizeFirstLetter(user?.fname)}</h2>
              <span className="">{user?.email}</span>
              <p className="center gap-2 mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#70b452"
                  className="bi bi-patch-check-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                </svg>
                <span>Verified Account Holder</span>
              </p>
              {tab === 0 && <button onClick={()=>{setTab(1)}} className="update-button rounded-pill px-5 mt-3">
                  {loading? (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    "EDIT PROFILE"
                  )}
              </button>}
            </div>
            {tab === 1 && <form className="account-form">
              <div className="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#747474 "
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                  />
                </svg>
                <input
                  value={form?.fname}
                  name="fname"
                  onChange={handleChange}
                  type="text"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#747474"
                  className="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
                <input
                  value={form?.email}
                  name="email"
                  onChange={handleChange}
                  type="email"
                  placeholder="Email"
                  readOnly
                />
              </div>
              <div className="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#747474"
                  className="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                </svg>
                <input
                  value={user?.mobile}
                  name="mobile"
                  onChange={handleChange}
                  type="tel"
                  placeholder="Phone Number"
                  required
                  readOnly
                />
              </div>
              <div className="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#747474"
                  className="bi bi-gender-male"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
                  />
                </svg>
                <select
                  value={form?.gender}
                  onChange={handleChange}
                  name="gender"
                  required
                >
                  <option value="" disabled selected>
                    Select Gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#747474"
                  className="bi bi-flag"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                </svg>
                <select
                  value={form?.state}
                  onChange={handleChange}
                  name="state"
                  required
                >
                  <option value="" disabled selected>
                    Select State
                  </option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <PasswordSvg />
                <input
                  name="password"
                  onChange={handleChange}
                  type="text"
                  placeholder="New Password"
                />
              </div>
              {user?.sponsor && <div className="form-group">
                <SponsorSvg />
                <span className="fw-bold">Sponsor: </span>
                <input
                  onChange={handleChange}
                  type="text"
                  value={user?.sponsor}
                />
              </div>}
              <button onClick={handleUpdate} className="update-button rounded-pill">
                  {loading? (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    "UPDATE PROFILE"
                  )}
              </button>
            </form>}
          </div>

          <div className="account-modal-bottom-content bg-white px-3 py-3 mt-2">
            <div className="content-item d-flex justify-content-between align-items-center rounded-4 px-2 py-2 my-2" onClick={()=>{handleButtonClick('/user-wallet')}}>
              <div className="center gap-3">
                <div className="center content-item-left-icon-container rounded-pill"><WalletIcon className="icon" fill="#33ab00"/></div>
                <span className="content-item-name">My Wallet</span>
              </div>
              <div className="content-item-right-icon-container bg-light rounded-pill center me-1">
                  <ChevronRightIcon />
              </div>
            </div>
            <div className="content-item d-flex justify-content-between align-items-center rounded-4 px-2 py-2 my-2" onClick={()=>{handleButtonClick('/all-queries')}}>
              <div className="center gap-3">
                <div className="center content-item-left-icon-container rounded-pill"><QuestionIcon className="icon" fill="#33ab00"/></div>
                <span className="content-item-name">All Queries</span>
              </div>
              <div className="content-item-right-icon-container bg-light rounded-pill center me-1">
                  <ChevronRightIcon />
              </div>
            </div>
            <div className="content-item d-flex justify-content-between align-items-center rounded-4 px-2 py-2 my-2" onClick={()=>{handleButtonClick('/refer-and-earn')}}>
              <div className="center gap-3">
                <div className="center content-item-left-icon-container rounded-pill"><RupeesIcon className="icon" fill="#33ab00"/></div>
                <span className="content-item-name">Refer & Earn</span>
              </div>
              <div className="content-item-right-icon-container bg-light rounded-pill center me-1">
                  <ChevronRightIcon />
              </div>
            </div>
            <div className="content-item d-flex justify-content-between align-items-center rounded-4 px-2 py-2 my-2" onClick={handleLogout}>
              <div className="center gap-3">
                <div className="center content-item-left-icon-container rounded-pill"><LogoutIcon className="icon" fill="#33ab00"/></div>
                <span className="content-item-name text-danger">Logout</span>
              </div>
              <div className="content-item-right-icon-container bg-light rounded-pill center me-1">
                  <ChevronRightIcon />
              </div>
            </div>
          </div>



          <span className="center mt-4">
            <small>
              <p className="mb-0 fw-bold">
                All Rights Reserved © 2024 | TOPUP PLAYGROUND
              </p>
              <p className="mb-0">
                <span>Website designed & developed by</span>
                <Link
                  target="_blank"
                  className="text-danger"
                  to="https://aashirdigital.com/"
                >
                  ~@aashirdigital
                </Link>
              </p>
            </small>
          </span>
        </div>
      </div>

      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default AccountModal;
