import React from 'react';

const HomePageMobileAlert = ({popupData, onClose}) => {
  console.log(popupData)
  return (
    <>
    <div className="mobile-alert-container d-lg-none d-md-none px-3 mt-2">
        <div className="mobile-alert-content rounded-4 d-flex justify-content-between align-items-center p-2 gap-3">
            <div className="d-flex justify-content-between align-items-start h-100 gap-2">
            <img className="mobile-alert-content-image rounded-3 shadow" src={popupData.image} alt="img" />
            <div>
              <h2 className='mb-0'>{popupData.title}</h2>
              <p className="mb-0" onClick={()=>{window.location.href = popupData.link}}><small>{popupData.desc}</small></p>
            </div>
            </div>
            <div onClick={onClose}>
              <svg width="20" height="20" fill="currentColor" className="text-secondary me-2" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
            </div>
        </div>
    </div>
    </>
  )
}

export default HomePageMobileAlert
