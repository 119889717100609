import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { IoMdLogIn } from "react-icons/io";
import { IoChatbubblesOutline, IoWalletOutline } from "react-icons/io5";
import "./SideMenu.css";
import { IoMdLogOut } from "react-icons/io";
import { BsPersonFillUp } from "react-icons/bs";
import NotLoginPopupModal from "../Modal/NotLoginPopupModal";
import { setActiveButton, setShowAccountModal, setShowSupportModal } from '../../redux/features/footerMenuSlice';
import { setUser } from "../../redux/features/userSlice";
import AddMoneyModal from "../Modal/AddMoneyModal";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const { activeButton } = useSelector((state) => state.footerMenu);
  const [pathName, setPathName] = useState('/');
  const [showModal, setShowModal] = useState(false);
  const [showAddMoneyModal, setShowAddMoneyModal] = useState(false);

  useEffect(()=>{
    if(location){
      setPathName(location.pathname)
    }
    // eslint-disable-next-line
  }, [location])

  const handleLogout = () => {
    dispatch(setUser(null));
    localStorage.clear();
    message.success("Logout Successful");
    setSideMenu(false);
    navigate('/');
    dispatch(setActiveButton('home'));
    dispatch(setShowSupportModal(false));
    dispatch(setShowAccountModal(false));
  };

  const closeMenu = (e) => {
    e.stopPropagation()
    setSideMenu(false)
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';

    return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  };
  const getFirstLetter = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };

  const ChevronRightIcon = ({ width = 10, height = 10, fill = "#212529" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} className="bi bi-chevron-right" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
    </svg>
  );
  const Dot = ({ width = 10, height = 10, fill = "#78a943" }) => (
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="5" fill={fill} />
    </svg>
  );

  const myAccountClick = ()=>{
    if(!user){
      setShowModal(true);
    }else{
      navigate('/');
      dispatch(setShowAccountModal(true));
      dispatch(setShowSupportModal(false));
      setSideMenu(false);
      dispatch(setActiveButton('account'));
    }
  }
  const onSupportClick = ()=>{
      navigate('/')
      setSideMenu(false);
      dispatch(setShowSupportModal(true));
      dispatch(setShowAccountModal(false));
      dispatch(setActiveButton('support'));
  }
  const onHomeClick = (e)=>{
    navigate('/');
    closeMenu(e);
    dispatch(setActiveButton('home'));
    setSideMenu(false);
    dispatch(setShowAccountModal(false));
    dispatch(setShowSupportModal(false));
  }

  const onLinkClick = (path)=>{
    dispatch(setActiveButton('more'));
    dispatch(setShowAccountModal(false));
    dispatch(setShowSupportModal(false));
    navigate(path);
    setSideMenu(false);
    if(path === "/reports"){
      dispatch(setActiveButton("reports"));
    }
  }
  
  const onAddMoneyClick = ()=>{
    dispatch(setShowAccountModal(false));
    dispatch(setShowAccountModal(false));
    dispatch(setActiveButton('home'));
    setSideMenu(false)
    !user? setShowModal(true) : setShowAddMoneyModal(true)
  }
  
  return (
    <>
    <div className={`sidemenu-container d-lg-none d-md-none ${sideMenu ? "active" : ""}`} onClick={closeMenu} >
      <div className={`sidemenu ${sideMenu ? "active" : ""} px-3`} onClick={(e) => e.stopPropagation()}>

        {/* Sidebar Menu Top */}
        <div className="pt-4 header-mobile d-flex d-md-none d-lg-none justify-content-between align items-center gap-2">
          {user? <div className="d-flex justify-content-start flex-column align-items-start" onClick={myAccountClick}>
            <span className="text-sm sidebar-mobile-user">{capitalizeFirstLetter('welcome back')}</span>
            <div className="center" style={{ marginLeft: "-2px" }}>
              <div className="sidebar-mobile-price center text-dark" style={{letterSpacing: "-0.6px"}}>{capitalizeFirstLetter(user?.fname)}</div>
            </div>
          </div>
          :
          <div className="d-flex justify-content-center flex-column align-items-start" onClick={()=>{navigate('/login')}}>
            <span className="text-sm sidebar-mobile-user m-0 text-dark">Welcome</span>
            <span className="d-flex justify-content-center align-items-center">  
              <span className="text-sm sidebar-mobile-user login m-0 text-primary">Please Login</span>
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-up-right-square text-primary ms-2"
                viewBox="0 0 16 16"
            >
                <path
                fillRule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"
                />
              </svg>
            </span>
          </div>
          }
          <div className="center sidebar-mobile-content-2">
            {user?
            <div className="rounded-box bg-color center shadow pointer" onClick={myAccountClick}>
              <span className={`${user? 'sidebar-mobile-text' : 'sidebar-mobile-login-icon'} text-white center`}>{getFirstLetter(user?.fname)}</span>
            </div>
            :  
            <div className="rounded-box bg-color center shadow pointer" onClick={()=>{navigate('/login')}}>
              <span className={`${user? 'sidebar-mobile-text' : 'sidebar-mobile-login-icon'} text-white center`}>{<IoMdLogIn/>}</span>
            </div>
            }
          </div>
        </div>

        <div>
          <hr className="m-0 light-hr my-3"/>
        </div>

        {/* MENU LIST */}
        <div className="w-100 d-flex jsutify-content-between align-items-center mb-3" onClick={onHomeClick}>
          <Link to="/" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-1 center p-2 ${activeButton === 'home' && 'active'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-house-fill text-white`} viewBox="0 0 16 16">
                  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"/>
                  <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z"/>
                </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Home</span>
          </Link>
          <div className="center">
            {activeButton === 'home'? <Dot /> : <ChevronRightIcon />}
          </div>
        </div>

        {/*ADMIN BUTTOn */}
        {user?.isAdmin && <div className="w-100 d-flex jsutify-content-between align-items-center mb-3">
          <Link to="/admin-dashboard" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-1 center p-2`}>
              <svg width="16" height="16" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1"/>
                <path d="M3.904 9.223C2.875 8.755 2 8.007 2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.5 4.5 0 0 0-1.364-.125 3 3 0 0 0-2.197.731 4.5 4.5 0 0 0-1.254 1.237A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777M8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16q.134 0 .266-.003A2 2 0 0 1 8 15zm0-1.5q0 .15.01.3A2 2 0 0 0 8 13c-1.573 0-3.022-.289-4.096-.777C2.875 11.755 2 11.007 2 10v-.839c.457.432 1.004.751 1.49.972C4.722 10.693 6.318 11 8 11q.13 0 .257-.002A4.5 4.5 0 0 0 8 12.5"/>
                <path d="M9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1"/>
              </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Admin</span>
          </Link>
          <div className="center">
            <ChevronRightIcon />
          </div>
        </div>}

        <div className="w-100 d-flex jsutify-content-between align-items-center mb-3" onClick={myAccountClick}>
          <div className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-1 center p-2 ${activeButton === 'account' && 'active'}`}>
                <svg width="17" height="17" fill="currentColor" className={`bi bi-house-fill text-white`} viewBox="0 0 16 16">
                    <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4m9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">My Account</span>
          </div>
          <div className="center">
            {activeButton === 'account'? <Dot /> : <ChevronRightIcon />}
          </div>
        </div>
        
        <div className="w-100 d-flex jsutify-content-between align-items-center mb-3" onClick={onSupportClick}>
          <div className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-4 center p-2 ${activeButton === 'support' && 'active'}`}>
                <svg width="17" height="17" fill="currentColor" className={`bi bi-house-fill text-white`} viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                  </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Help & Support</span>
          </div>
          <div className="center">
            {activeButton === 'support'? <Dot /> : <ChevronRightIcon />}
          </div>
        </div>

        <div className="w-100 d-flex jsutify-content-between align-items-center mb-3" onClick={()=>{onLinkClick('/privacy-policy')}}>
          <Link to="/privacy-policy" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-2 center p-2 ${pathName === '/privacy-policy' && 'active'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-house-fill text-white`} viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 0 0 1.048-.625 11.8 11.8 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 0 0-1.044-1.263 63 63 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793z"/>
                </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Privacy & Policy</span>
          </Link>
          <div className="center">
            {pathName === "/privacy-policy"? <Dot /> : <ChevronRightIcon />}
          </div>
        </div>

        <div className="w-100 d-flex jsutify-content-between align-items-center mb-3" onClick={()=>{onLinkClick('/terms')}}>
          <Link to="/terms" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-1 center p-2 ${pathName === '/terms' && 'active'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-house-fill text-white`}  viewBox="0 0 16 16">
                  <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0M7 7v1h2V7a1 1 0 0 0-2 0"/>
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Term & Condition</span>
          </Link>
          <div className="center">
            {pathName === "/terms"? <Dot /> : <ChevronRightIcon />}
          </div>
        </div>
        
        <div className="w-100 d-flex jsutify-content-between align-items-center" onClick={()=>{onLinkClick('/refund-policy')}}>
          <Link to="/refund-policy" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <div className={`sidebar-menu-list-icon color-3 center p-2 ${pathName === '/refund-policy' && 'active'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-house-fill text-white`} viewBox="0 0 16 16">
                  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                </svg>
            </div>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Refund Policy</span>
          </Link>
          <div className="center">
            {pathName === "/refund-policy"? <Dot /> : <ChevronRightIcon />}
          </div>
        </div>
        
        <div>
          <hr className="m-0 light-hr my-3"/>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="sidebar-menu-content-2-div me-2" onClick={onAddMoneyClick}>
            <div className={`sidebar-menu-section-2-icon color-1 center`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                  <path d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
                </svg>
            </div>
            <span className="text-start sidebar-menu-section-2-icon-name m-0 text-dark">Add Money</span>
          </div>
          <div className="sidebar-menu-content-2-div" onClick={()=>{onLinkClick('/reports')}}>
            <div className={`sidebar-menu-section-2-icon color-2 center `}>
                <svg width="18" height="18" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                </svg>
            </div>
            <span className="text-start sidebar-menu-section-2-icon-name m-0 text-dark">Reports</span>
          </div>
          <div className="sidebar-menu-content-2-div" onClick={()=>{onLinkClick('/refer-and-earn')}}>
            <div className={`sidebar-menu-section-2-icon color-3 center `}>
                <svg width="18" height="18" fill="currentColor" className="text-white" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                    <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
                </svg>
            </div>
            <span className="text-start sidebar-menu-section-2-icon-name m-0 text-dark">Refer & Earn</span>
          </div>
        </div>

        <div>
          <hr className="m-0 light-hr my-3"/>
        </div>

        {/* USEFUL Links */}
        <span className="sidebar-menu-usefullinks-name text-secondary">Useful Links</span>
        {user && 
          <>
            <div className="w-100 d-flex jsutify-content-between align-items-center mb-2" onClick={()=>{onLinkClick('/user-wallet')}}>
              <Link to="/user-wallet" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
                <IoWalletOutline className="fs-5 text-secondary"/>
                <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">My Wallet</span>
              </Link>
              <div className="center">
                <ChevronRightIcon />
              </div>
            </div>
            <div className="w-100 d-flex jsutify-content-between align-items-center mb-2" onClick={()=>{onLinkClick('/all-queries')}}>
              <Link to="/" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
                <IoChatbubblesOutline className="fs-5 text-secondary"/>
                <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">All Queries</span>
              </Link>
              <div className="center">
                <ChevronRightIcon />
              </div>
            </div>
            <div className="w-100 d-flex jsutify-content-between align-items-center" onClick={handleLogout}>
              <Link to="/" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
                <IoMdLogOut className="fs-4 text-secondary"/>
                <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Log Out</span>
              </Link>
              <div className="center">
                <ChevronRightIcon />
              </div>
            </div>
          </>
        }
        {!user && <div className="w-100 d-flex jsutify-content-between align-items-center mb-3" onClick={()=>{onLinkClick('/login')}}>
          <Link to="/" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <IoMdLogIn className="fs-5 text-secondary"/>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Log In</span>
          </Link>
          <div className="center">
            <ChevronRightIcon />
          </div>
        </div>}
        {!user && <div className="w-100 d-flex jsutify-content-between align-items-center" onClick={()=>{onLinkClick('/register')}}>
          <Link to="/" className="w-100 d-flex jsutify-content-between align-items-center gap-3">
            <BsPersonFillUp className="fs-5 text-secondary"/>
            <span className="text-start sidebar-mobile-menu-list-name m-0 text-dark">Register</span>
          </Link>
          <div className="center">
            <ChevronRightIcon />
          </div>
        </div>}

        <div>
          <hr className="m-0 light-hr my-3"/>
        </div>
        
        {/* Footer */}
        <p className="sidebar-menu-copyright opacity-30 color-theme mt-n3">
          <span className="copyright-year">Copyright 2024</span>. Made with
          <svg width="10" height="10" fill="currentColor" className="bi bi-heart-fill text-danger mx-1" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
          </svg>
          by
          &nbsp;
          <a href="https://aashirdigital.com/" className="color-highlight" target="_blank">Ashir Digital</a>
        </p>

      </div>
    </div>
    <NotLoginPopupModal string="Please login to see your account details." show={showModal} onClose={()=> setShowModal(false)}/>
    <AddMoneyModal showAddMoneyModal={showAddMoneyModal} setShowAddMoneyModal={setShowAddMoneyModal}/>
    </>
  );
};

export default SideMenu;
