import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: null,
  banners: null,
  allOrders: null,
  singlePurchase: null,
  balance: "",
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setBanners: (state, action) => {
      state.banners = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },
    setSinglePurchase: (state, action) => {
      state.singlePurchase = action.payload;
    },
  },
});

export const { setProducts, setBanners, setBalance, setAllOrders, setSinglePurchase } =
  dataSlice.actions;

export default dataSlice.reducer;
