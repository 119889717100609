import React, { useEffect, useState } from 'react';
import AdminLayout from './components/AdminLayout';
import { message, Modal, Button } from 'antd';
import axios from 'axios';

const AdminAddMoney = () => {
  const [form, setForm] = useState({ email: '', amount: '', category: '', desc: '' });
  const [itemId, setItemId] = useState('');
  const [loading, setLoading] = useState(false);
  const [allAddedMoney, setAllAddedMoney] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const generateItemId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}`;
    setItemId(orderId);
  };

  const validateForm = () => {
    if (!form.email || !form.amount || !form.category || !form.desc) {
      message.error('All fields are required');
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(form.email)) {
      message.error('Invalid email format');
      return false;
    }
    if (isNaN(form.amount) || form.amount <= 0) {
      message.error('Amount should be a positive number');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await axios.post(
        '/api/admin/add-money-user-wallet',
        { ...form, itemId },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ email: '', amount: '', category: '', desc: '' });
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error('Failed to add money to user wallet');
    } finally {
      setLoading(false);
      generateItemId();
      getAllAddedMoneyToUserWallet();
    }
  };

  async function getAllAddedMoneyToUserWallet() {
    try {
      const res = await axios.get('/api/admin/get-all-added-money-user-wallet', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      if (res.data.success) {
        setAllAddedMoney(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const showModal = (desc) => {
    setSelectedDesc(desc);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAllAddedMoneyToUserWallet();
    generateItemId();
    // eslint-disable-next-line
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Add Money to User Wallet</h3>
        <h6>Total Data - {allAddedMoney?.length}</h6>
      </div>
      <hr />
      <div className="noti-container">
        <form className="form-fields" onSubmit={handleSubmit}>
          <input
            type="text"
            name="email"
            className="form-control mb-3"
            placeholder="Enter User Email Id"
            onChange={handleChange}
            value={form.email}
          />
          <input
            type="number"
            name="amount"
            className="form-control mb-3"
            placeholder="Enter Amount"
            onChange={handleChange}
            value={form.amount}
          />
          <select
            name="category"
            className="form-control mb-3"
            value={form.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            <option value="Refund">Refund</option>
            <option value="Add Money">Add Money</option>
          </select>
          <input
            type="text"
            name="desc"
            className="form-control mb-3"
            placeholder="Reason"
            onChange={handleChange}
            value={form.desc}
          />
          <button type="submit" className="btn btn-primary">
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
        <div className="mt-5 overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Item ID</th>
                <th>Email</th>
                <th>Before</th>
                <th>Amount</th>
                <th>After</th>
                <th>Date</th>
                <th>Desc</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {allAddedMoney?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.itemId}</td>
                  <td>{item.email}</td>
                  <td>{item.beforeBalance}</td>
                  <td>{item.amount}</td>
                  <td>{item.afterBalance}</td>
                  <td className='no-wrap'>{new Date(item.date).toLocaleString()}</td>
                  <td className='no-wrap center'>
                    <button className="btn btn-secondary p-0 px-1" onClick={() => showModal(item.desc)}>
                      <small>View</small>
                    </button>
                  </td>
                  <td className='no-wrap'>{item.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal title="Description" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>{selectedDesc}</p>
      </Modal>
    </AdminLayout>
  );
};

export default AdminAddMoney;
