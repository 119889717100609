import React, { useEffect, useState } from 'react';
import './SinglePaymentModalReport.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { message } from 'antd';
import axios from 'axios';

const SinglePaymentModalReport = ({ onClose, item }) => {

  const [walletHistory, setWalletHistory] = useState(null);
  
  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "processing":
        return "warning";
      case "success":
        return "success";
      case "Success":
        return "success";
      case "rejected":
        return "danger";
      case "failure":
        return "danger";
      case "failed":
        return "danger";
      case "refunded":
        return "primary";
      case "cancelled":
        return "dark";
      default:
        return "";
    }
  };

  const getWalletHistory = async(elm)=>{
    try {
      const res = await axios.post(
        "/api/wallet/get-wallet-history-by-orderId",
        { orderId: item?.orderId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setWalletHistory(res.data.data);
      } 
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    if(item?.paymentType === 'wallet'){
      // getWalletHistory(item);
    }
    // eslint-disable-next-line
  }, [item])

  const copyToClipboard = (event, text) => {
    event.stopPropagation(); 
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Order ID copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy!');
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="SinglePaymentModalReport-modal-overlay">
      <div className={`SinglePaymentModalReport-modal-content border-bottom border-3 border-${getStatusClass(item.status)}`}>
        <button className="SinglePaymentModalReport-close-button rounded-pill center" onClick={onClose}>+</button>
        {item.paymentType === "wallet" && <h2>Add Money to Wallet</h2>}
        {item.paymentType !== "wallet" && <h2>Payment Details</h2>}
       
        <p className='d-flex justify-content-start align-items-center gap-2'>
          <strong>Order ID:</strong>
          <span className='px-2 py-1 border bg-light rounded center gap-1' onClick={(event)=>{copyToClipboard(event, `Purchase Id: ${item?.orderId}`)}}>
            <span>{item.orderId}</span>
            <ContentCopyIcon className='fs-6'/>
          </span>
        </p>
        {/* {item.paymentType === "wallet"  && <p><strong>Total Wallet Amount:</strong> {walletHistory? `₹${parseFloat(walletHistory?.balanceAfter).toFixed(2)}` : 'Loading...'}</p>} */}
        <p><strong>Amount:</strong> ₹{parseFloat(item.amount).toFixed(2)}</p>
        <p className={`text-${getStatusClass(item.status)}`}><strong>Status:</strong> {(item.status)?.toLowerCase()}</p>
        <p><strong>Date:</strong> {new Date(item.createdAt).toLocaleString()}</p>
        {item.paymentType !== "wallet" && <p><strong>Product Info:</strong> {item.productInfo}</p>}
        {/* Add more details as needed */}
      </div>
    </div>
  );
};

export default SinglePaymentModalReport;
