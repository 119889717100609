import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alerts", // Ensure the slice name is correct
  initialState: {
    loading: false,
    showPopup: false, // Initialize showPopup as false
  },
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
  },
});

export const { showLoading, hideLoading, setShowPopup } = alertSlice.actions;

// Make sure this is properly integrated into the store
