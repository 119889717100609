import React from 'react';
import AddCardIcon from '@mui/icons-material/AddCard';

const AllAddedMoneyReports = ({ item }) => {

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "refund":
        return "warning";
      case "processing":
        return "warning";
      case "success":
        return "success";
      case "Success":
        return "success";
      case "rejected":
        return "danger";
      case "failure":
        return "danger";
      case "failed":
        return "danger";
      case "Add Money":
        return "success";
      case "Refund":
        return "primary";
      default:
        return "";
    }
  };

  return (
    <>
      <div
        key={item?.orderId}
        className="list w-100 d-flex justify-content-between align-items-center mt-2 p-2 border border-bottom rounded-4 bg-light"
      >
        <div className="center gap-2">
          <div className="add-icon-box">
            <AddCardIcon />
          </div>
          <div className="d-flex justify-content-center align-items-start flex-column">
            <span className="add-money">
              {item?.category?.toUpperCase()}
            </span>
            <span className="payment-order-id">
              {item?.itemId}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-end flex-column">
          <h4 className={`mb-0 payment-amount ${item?.category === "Add Money"? "" : "text-primary"}`}>
            ₹{parseFloat(item?.amount).toFixed(2)}
          </h4>
          <div className="center gap-2">
            <span className="payment-date no-wrap">
              {new Date(item?.date).toLocaleString("default", {
                day: "2-digit",
                month: "short",
              })}
            </span>
            <span className={`text-${getStatusClass(item?.category)} payment-status no-wrap`}>
                Total: ₹{parseFloat(item?.afterBalance).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAddedMoneyReports;
